import getAlternateUrl from '~/utils/alternate-url'

export default function serializeJSONLD({ links }) {
  return {
    itemListElements: links?.map((link, index) => {
      return {
        position: `${index + 1}`,
        name: link?.children,
        item: link?.href ? getAlternateUrl(link.href) : '',
      }
    }),
  }
}
