import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'
import { FooterLogoProps } from '~/@types/footer'

import { Link } from '@unlikelystudio/react-abstract-components'

import Logotype from '~/components/UI/LogoTypes/logo-illu.svg'

import useLocale from '~/hooks/useLocale'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function FooterLogo({ className, theme, hasNoLink }: FooterLogoProps) {
  const t = useTranslate()
  const locale = useLocale()

  const linkProps = {
    className: cx(css.FooterLogo, className),
    title: t(GENERAL.LOGO_TITLE),
    href: `/${locale}`,
  }
  return hasNoLink ? (
    <div className={linkProps.className}>
      <Logotype className={cx(css.logo, css?.[`theme-${theme}`])} />
    </div>
  ) : (
    <Link {...linkProps}>
      <Logotype className={cx(css.logo, css?.[`theme-${theme}`])} />
    </Link>
  )
}

FooterLogo.defaultProps = {
  hasNoLink: false,
}

export default FooterLogo
