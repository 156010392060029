import classnames from 'classnames/bind'
import {
  FooterColumnProps,
  FooterTextColors,
  FooterTheme,
} from '~/@types/footer'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import {
  Accordion,
  AccordionPanel,
} from '@unlikelystudio/react-abstract-components'

import PopIn from '~/components/Abstracts/PopIn'
import ContactPopin from '~/components/Popins/ContactPopin'
import { CloseIcon } from '~/components/UI/Icons'
import InlineCta from '~/components/UI/InlineCta'

import { usePopIn } from '~/providers/PopInProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type FooterMobileColumnsProps = {
  className?: string
  columns?: FooterColumnProps[]
  theme?: FooterTheme
  textThemes?: FooterTextColors
}

function FooterMobileColumns({
  className,
  columns,
  textThemes,
  theme,
}: FooterMobileColumnsProps) {
  const { add: addPopin } = usePopIn()
  const panelHeadStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
    textPreset: GlobalTextPreset.Cta16Grotesk,
  })

  const triggerStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
    textPreset: GlobalTextPreset.Cta12Grotesk,
  })

  const panelBodyStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
  })

  const onClickItem = (e) => {
    e.preventDefault()

    addPopin(
      <PopIn
        className={cx(css.popin)}
        isCloseButton={true}
        closeButton={<CloseIcon className={css.popinClose} />}>
        <ContactPopin className={cx(css.contactPopin)} />
      </PopIn>,
    )
  }

  return (
    <div
      className={cx(
        css.FooterMobileColumns,
        className,
        css?.[`theme-${theme}`],
      )}>
      {columns?.length > 0 && (
        <Accordion>
          {columns?.map(({ mobileTitle, links }, key) => (
            <AccordionPanel
              key={`panel_${key}`}
              className={cx(css.panel)}
              index={key}
              head={(isActive: boolean) => (
                <div
                  className={cx(css.panelHead, {
                    isActive,
                  })}>
                  <span className={cx(css.title, panelHeadStyle)}>
                    {mobileTitle}
                  </span>
                  <span className={cx(css.state, triggerStyle)}>
                    {isActive ? `(—)` : `(+)`}
                  </span>
                </div>
              )}
              body={
                <div className={cx(css.panelBody)}>
                  {links?.length > 0 &&
                    links?.map((item, key) => {
                      const { isContactLink, ...rest } = item
                      return (
                        <div
                          key={`link_mobile_${key}`}
                          className={cx(css.item, panelBodyStyle)}>
                          {item && (
                            <div className={cx(css.linkContainer)}>
                              <InlineCta
                                className={css.link}
                                theme={textThemes.dilutedLink}
                                textPreset={
                                  GlobalTextPreset.Cta12GroteskRegular
                                }
                                textStyling={GlobalTextStyling.UpperCase}
                                lineProps={{ initialLineState: 'none' }}
                                onClick={
                                  item?.isContactLink
                                    ? (e) => {
                                        onClickItem(e)
                                      }
                                    : null
                                }
                                {...rest}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                </div>
              }
            />
          ))}
        </Accordion>
      )}
    </div>
  )
}

FooterMobileColumns.defaultProps = {}

export default FooterMobileColumns
