import classnames from 'classnames/bind'
import { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { FooterProps, FooterTextColors } from '~/@types/footer'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Breadcrumb from '~/components/UI/Breadcrumb'
import FooterColumn from '~/components/UI/Footer/FooterColumn'
import FooterLogo from '~/components/UI/Footer/FooterLogo'
import FooterMobileColumns from '~/components/UI/Footer/FooterMobileColumns'
import Image from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'
import RichText from '~/components/UI/RichText'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useStyle } from '~/providers/StyleProvider'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function getTextColorsFromTheme(theme: GlobalThemeColors): FooterTextColors {
  switch (theme) {
    case GlobalThemeColors.Black:
      return {
        dilutedLink: GlobalThemeColors.SilverChalice,
        breadcrumbTheme: GlobalThemeColors.White,
      }
    case GlobalThemeColors.William:
    case GlobalThemeColors.Eunry:
    case GlobalThemeColors.IrishCoffee:
      return {
        dilutedLink: GlobalThemeColors.Concrete,
        breadcrumbTheme: GlobalThemeColors.Concrete,
      }
    case GlobalThemeColors.Amber:
      return {
        dilutedLink: GlobalThemeColors.Black,
        breadcrumbTheme: GlobalThemeColors.Black,
      }
    default:
      return {
        dilutedLink: GlobalThemeColors.SilverChalice,
        breadcrumbTheme: GlobalThemeColors.White,
      }
  }
}

function Footer({
  className,
  columns,
  reassuranceItems,
  socialLinks,
  customerService,
  bottomNav,
  bottomIcons,
  withBreadCrumb = true,
}: FooterProps) {
  const t = useTranslate()
  const { theme } = useGlobalData()
  const footerTheme = theme?.footer
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const computedTheme = getTextColorsFromTheme(footerTheme)
  const reassuranceStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const customerServiceStyle = useStyle({
    textPreset: GlobalTextPreset.Cta10_12GroteskRegular,
    textStyling: GlobalTextStyling.UpperCase,
    color: computedTheme.dilutedLink,
  })

  const copyrightStyle = useStyle({
    textPreset: GlobalTextPreset.Cta10Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.Footer, className, css?.[`theme-${footerTheme}`])}>
      <div className={cx(gridStyle)}>
        {reassuranceItems?.length && (
          <div className={css.top}>
            <div className={css.reassurance}>
              {reassuranceItems?.map((item, index) => {
                return item?.children ? (
                  <Fragment key={`reassurance_item_${index}`}>
                    {item?.href ? (
                      <InlineCta
                        className={cx(css.item, reassuranceStyle)}
                        key={`reinsurance_item_${index}`}
                        textPreset={GlobalTextPreset.Cta10GroteskRegular}
                        textStyling={GlobalTextStyling.UpperCase}
                        theme={computedTheme.breadcrumbTheme}
                        lineProps={{ initialLineState: 'none' }}
                        {...item}
                      />
                    ) : (
                      <span
                        className={cx(reassuranceStyle, css.item)}
                        key={`reassurance_item_${index}`}>
                        {item?.children}
                      </span>
                    )}
                  </Fragment>
                ) : null
              })}
            </div>
            {withBreadCrumb && (
              <Breadcrumb
                className={css.breadcrumb}
                color={computedTheme.breadcrumbTheme}
              />
            )}
          </div>
        )}

        <div className={css.body}>
          <div className={css.bodyLeft}>
            <FooterLogo theme={footerTheme} className={css.logo} />

            <div className={css.columns}>
              {columns?.length > 0 && (
                <>
                  <div
                    className={cx(css.desktopColumns, css.hideOnSmallScreen)}>
                    {columns?.map((column, key) => {
                      return (
                        <FooterColumn
                          key={`column_desktop_${key}`}
                          className={css.desktopColumnItem}
                          textThemes={computedTheme}
                          {...column}
                        />
                      )
                    })}
                  </div>
                  <FooterMobileColumns
                    className={cx(css.mobileColumns, css.hideOnLargeScreen)}
                    textThemes={computedTheme}
                    theme={footerTheme}
                    columns={columns}
                  />
                </>
              )}
            </div>
          </div>

          <div className={css.bodyRight}>
            {socialLinks && (
              <div className={css.social}>
                {socialLinks.map((link, index) => {
                  return (
                    <InlineCta
                      key={`link_social_${index}`}
                      textPreset={GlobalTextPreset.Cta10_12GroteskRegular}
                      textStyling={GlobalTextStyling.UpperCase}
                      theme={computedTheme.dilutedLink}
                      className={cx(css.socialLink)}
                      lineProps={{ initialLineState: 'none' }}
                      {...link}
                    />
                  )
                })}
              </div>
            )}
            {customerService && (
              <RichText
                render={customerService}
                className={cx(
                  customerServiceStyle,
                  css.customerService,
                  css.hideOnSmallScreen,
                )}
              />
            )}
          </div>
        </div>
      </div>

      <div className={css.bottom}>
        <div className={gridStyle}>
          <div className={css.content}>
            <p
              className={cx(
                css.copyright,
                css.hideOnSmallScreen,
                copyrightStyle,
              )}>
              <span>{t(GENERAL.COPYRIGHT)}</span>
              <span>
                &nbsp; -&nbsp;
                <a href="https://unlikely.technology/" target="blank">
                  tech by Unlikely Technology
                </a>
              </span>
            </p>
            {bottomNav && (
              <div className={cx(css.bottomLinks)}>
                {bottomNav.map((link, index) => {
                  return (
                    <InlineCta
                      key={`link_bottom_${index}`}
                      textPreset={GlobalTextPreset.Cta10GroteskRegular}
                      textStyling={GlobalTextStyling.UpperCase}
                      theme={GlobalThemeColors.Gray}
                      className={cx(css.bottomLink)}
                      lineProps={{ initialLineState: 'none' }}
                      {...link}
                    />
                  )
                })}
              </div>
            )}
            {bottomIcons && (
              <div className={css.bottomIcons}>
                {bottomIcons.map((icon, index) => {
                  return (
                    <div
                      className={css.iconWrapper}
                      key={`bottom_icon_${index}`}>
                      <Image
                        {...icon}
                        layout="fixed"
                        width={28}
                        height={15}
                        className={css.icon}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Footer.defaultProps = {}

export default Footer
