import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import AbstractBreadcrumb from '~/components/Abstracts/Breadcrumb'
import { InlineCtaColors } from '~/components/UI/InlineCta'

import { useBreadCrumb } from '~/providers/BreadcrumbProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type FooterTheme = 'footer' | 'product'
export type BreadcrumbProps = {
  className?: string
  theme?: FooterTheme
  textPreset?: GlobalTextPreset
  textStyling?: GlobalTextStyling
  color?: InlineCtaColors
}

function Breadcrumb({
  className,
  textPreset,
  textStyling,
  color,
  theme,
  ...rest
}: BreadcrumbProps) {
  const textStyle = useStyle({
    color: color ?? GlobalThemeColors.Black,
    textPreset: textPreset ?? GlobalTextPreset.Cta12Grotesk,
    textStyling: textStyling ?? GlobalTextStyling.UpperCase,
  })

  const { links } = useBreadCrumb()

  return (
    <AbstractBreadcrumb
      links={links}
      className={cx(
        css.Breadcrumb,
        textStyle,
        className,
        css?.[`theme-${theme}`],
      )}
      linkClassName={cx(css.link, textStyle)}
      itemClassName={css.item}
      activeClassName={css.active}
      separatorClassName={css.separator}
      noLinkClassName={css.noLink}
      separatorItem="•"
      {...rest}
    />
  )
}

Breadcrumb.defaultProps = {
  theme: 'footer',
}

export default Breadcrumb
