import classnames from 'classnames/bind'
import { BreadcrumbJsonLd } from 'next-seo'
import { ReactNode } from 'react'
import { useTranslate } from 'react-polyglot'

import { Link, LinkProps } from '@unlikelystudio/react-abstract-components'

import serializeJSONLD from './serialize-json-ld'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export type BreadcrumbProps = {
  className?: string
  separatorClassName?: string
  itemClassName?: string
  activeClassName?: string
  linkClassName?: string
  noLinkClassName?: string
  separatorItem?: ReactNode
  links?: LinkProps[]
}

function Breadcrumb({
  className,
  separatorClassName,
  separatorItem,
  linkClassName,
  activeClassName,
  itemClassName,
  noLinkClassName,
  links,
}: BreadcrumbProps) {
  const t = useTranslate()

  const linksWithTrad = links?.map((link) => {
    const isTradKey =
      typeof link?.children === 'string' && link?.children?.includes('.')

    return {
      ...link,
      children: isTradKey
        ? typeof link?.children === 'string' && t(link?.children?.toLowerCase())
        : link?.children,
    }
  })

  return (
    <div className={cx(className)}>
      <BreadcrumbJsonLd {...serializeJSONLD({ links })} />
      <div className={css.Breadcrumb}>
        {linksWithTrad?.map((link, index) => {
          const isActive = index === links.length - 1

          return (
            <div
              className={cx(
                css.item,
                itemClassName,
                isActive ? activeClassName : null,
              )}
              key={index}>
              <Link
                className={cx(
                  link?.href ? null : (css.noLink, noLinkClassName ?? null),
                  css.link,
                  linkClassName,
                )}
                {...link}
              />
              {index + 1 !== links?.length && (
                <span className={separatorClassName}>
                  {separatorItem ?? `/`}
                </span>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

Breadcrumb.defaultProps = {}

export default Breadcrumb
