import classnames from 'classnames/bind'
import { FooterColumnProps } from '~/@types/footer'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import PopIn from '~/components/Abstracts/PopIn'
import ContactPopin from '~/components/Popins/ContactPopin'
import { CloseIcon } from '~/components/UI/Icons'
import InlineCta from '~/components/UI/InlineCta'

import { usePopIn } from '~/providers/PopInProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function FooterColumn({ className, links, textThemes }: FooterColumnProps) {
  const { add: addPopin } = usePopIn()

  const hasLinks = links?.length > 0

  const onClickItem = (e) => {
    e.preventDefault()

    addPopin(
      <PopIn
        className={cx(css.popin)}
        isCloseButton={true}
        closeButton={<CloseIcon className={css.popinClose} />}>
        <ContactPopin className={cx(css.contactPopin)} />
      </PopIn>,
    )
  }

  return (
    <div className={cx(css.FooterColumn, className)}>
      {hasLinks && links?.length > 0 && (
        <div className={cx(css.items)}>
          {links.map((item, key) => {
            const { isContactLink, ...rest } = item
            return (
              <InlineCta
                key={`link_${key}`}
                textPreset={GlobalTextPreset.Cta10_12GroteskRegular}
                textStyling={GlobalTextStyling.UpperCase}
                theme={textThemes.dilutedLink}
                className={cx(css.link)}
                lineProps={{ initialLineState: 'none' }}
                onClick={
                  item?.isContactLink
                    ? (e) => {
                        onClickItem(e)
                      }
                    : null
                }
                {...rest}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

FooterColumn.defaultProps = {}

export default FooterColumn
